<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">
        <span class="font-weight-bold">Phiếu đăng ký học tập nhãn đối tác</span>
      </div>
    </div>
    <div class="card-body">
      <div class="mt-6">
        <RegisterStudyFormPartner
            :errorsForm="errorsForm"
            v-if="active === 1"
            :contract_prop="contract"
            @dataContract="dataContract"
        ></RegisterStudyFormPartner>
      </div>
    </div>
    <div class="card-header d-flex justify-content-end">
      <button class="btn btn-success" :disabled="is_loading_submit || !contract.is_verify" @click="createStudy"><i
          v-if="is_loading_submit"
          class="el-icon-loading"></i>Tạo phiếu
      </button>
      <!--      <button class="btn btn-default ml-2">Quay lại</button>-->
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

import Multiselect from "vue-multiselect";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import RegisterStudyForm from "@/views/pages/customer/components/RegisterStudyForm";
import CompletedStudyForm from "@/views/pages/customer/components/CompletedStudyForm";
import CustomerConfirmForm from "@/views/pages/customer/components/CustomerConfirmForm";
import {CONTRACT_FIND_BY_CUSTOMER, CONTRACT_ONLINE_CREATE, CONTRACT_ONLINE_GET_BY_ID} from "@/core/services/store/contract/contractOnline.module";
import RegisterStudyFormPartner from "@/views/pages/customer/components/RegisterStudyFormPartner";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {CUSTOMER_UPDATE_LEVEL} from "@/core/services/store/customer/customer.module";
import {LEVEL_3A} from "@/core/config/customer/customerOption";

const _ = deepdash(lodash);
export default {
  name: "RegisterStudyPartner",
  props: {},
  components: {
    RegisterStudyFormPartner,
    CustomerConfirmForm,
    CompletedStudyForm,
    RegisterStudyForm,
    Multiselect
  },
  data() {
    return {
      errorsForm: {},
      active: 1,
      is_loading_submit: false,
      contract: {
        id: '',
        uuid: '',
        uuid_secret: '',
        is_older: true,
        tuition_payment_id: 1,
        name: '',
        phone: '',
        phone_other: '',
        email: '',
        birthday: '',
        center_id: '',
        branch_id: '',
        confirm: 1,
        total: '',
        courses: [
          {
            id: "",
            name: "",
            fee: 0
          }
        ],
        student_register_courses : [
          {
            course: {},
            fee: '',
          }
        ],
        gift_id: [],
        is_verify: false,
        customer_id: this.$route.query.customer_id,
        parent: {
          type: 'bo',
          other_value: '',
          name: '',
          phone: '',
          phone_other: '',
        }
      },
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách hợp đồng tư vấn"},
      {title: "Phiếu đăng ký học tập", icon: 'far fa-user'}
    ]);
    await this.getContractByCustomer();
    this.getById();
  },
  methods: {
    getContractByCustomer(){
      let customer_id = this.$route.query.customer_id;
      return this.$store.dispatch(CONTRACT_FIND_BY_CUSTOMER, customer_id).then((data) => {
        this.contract.id = data.data;
      });
    },    
    dataContract(data) {
      this.contract = data;
    },
    createStudy() {
      this.is_loading_submit = true;
      this.contract.confirm = 1;
      this.contract.status = 1;
      this.$store.dispatch(CONTRACT_ONLINE_CREATE, this.contract).then((data) => {
        this.$message.success(data.message);
        this.updateLevel3A(this.contract.customer_id);
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.errorsForm = e.data.data.message_validate_form;
          this.$message.error(Object.values(this.errorsForm)[0][0]);
        }
      }).finally(() => {
        this.is_loading_submit = false;
      })
    },
    updateLevel3A(customer_id) {
      this.$store.dispatch(CUSTOMER_UPDATE_LEVEL, {
        customer_id: customer_id,
        level: LEVEL_3A
      }).then(() => {
        this.$router.push({
          name: "contract-index"
        })
      }).catch((e) => {
        this.$message.error(e.data.message);
      })
    },
    getById() {
      this.$store.dispatch(CONTRACT_ONLINE_GET_BY_ID, this.contract.id).then((data) => {
        this.contract = data.data;
      });
    },
    completedStudy() {
      this.next();
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    back() {
      if (this.active-- > 0) this.active = 1;
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.withdraw-course {
  position: absolute;
  right: 20px;
  top: 37px;
}
</style>
